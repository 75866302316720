$primary-color: #128275;
$border-color-base: #d9d9d9;

.print {
	.black {
		color: black;
	}
	.green {
		color: $primary-color;
	}
	.white {
		color: white;
	}

	.print-footer {
		margin-top: 50px;
		position: relative;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		width: 100%;
		font-size: 10px !important;

		.alymente {
			span {
				display: block;
				width: 100%;
			}
		}
	}

	.smallInfos {
		font-size: 12px !important;
	}

	.table-detail {
		border: 1px solid $border-color-base;
		border-radius: 6px;

		th,
		td {
			padding: 7px 10px;
		}
		th {
			background-color: $primary-color;
		}

		thead > tr:first-child th:first-child {
			border-top-left-radius: 6px;
		}

		thead > tr:first-child th:last-child {
			border-top-right-radius: 6px;
		}

		tr > td {
			border-top: 1px solid $border-color-base;
		}

		tbody tr:last-child td:first-child {
			border-bottom-left-radius: 12px;
		}

		tbody tr:last-child td:last-child {
			border-bottom-right-radius: 12px;
		}
	}
}

.print-body {
	font-size: 10px !important;

	.print-content {
		padding: 20px;
		.table-detail {
			table {
				th,
				td {
					text-size-adjust: 50%;
					font-size: 5px !important;
					padding: 2px;
				}
				thead > th {
					font-size: 2px !important;
				}
			}
		}
	}

	.smallInfos,
	.green,
	h3 {
		font-size: 10px !important;
	}

	h2 {
		font-size: 11px !important;
	}

	.black,
	.white {
		font-size: 7px !important;
	}

	.print-footer {
		padding: 20px 10px;
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;
		font-size: 6px !important;

		.logo-login {
			width: 80px;
		}
	}
}
