body {
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

@import "./print.scss";
@import "./inputcurrency.scss";
@import "./menu.scss"
