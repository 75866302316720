$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$primary-color: #128275;
$border-color: #d9d9d9;
$background-light: rgba(0, 0, 0, 0.02);
$box-shadow-focus: rgba(5, 145, 255, 0.1);

@mixin font-stack {
  font-family: $font-stack;
}

.input-currency-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
  @include font-stack;
  box-sizing: border-box;
}

.input-currency-wrapper {
  @include font-stack;
  margin: 0;
  padding: 0;
  color: $primary-color;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.input-currency-group-addon {
  border-right: 0;
  position: relative;
  padding: 0px 11px;
  color: $primary-color;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: $background-light;
  border: 1px solid $border-color;
  border-radius: 6px;
  transition: all 0.3s;
  line-height: 1;
  display: table-cell;
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-currency {
  @include font-stack;
  width: 100%;
  margin: 0;
  padding: 4px 11px;
  color: $primary-color;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  display: table-cell;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:focus {
    z-index: 1;
    border-right-width: 1px;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px $box-shadow-focus;
    outline: 0;
  }
  &:disabled {
    color: #6f6f6f;
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
}

span.input-currency-group-addon-disabled {
  color: #6f6f6f;
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
